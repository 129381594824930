import { render, staticRenderFns } from "./info.vue?vue&type=template&id=424f2cdc&scoped=true&%3Ais=view&transition=fade&transition-mode=out-in&"
import script from "./info.vue?vue&type=script&lang=js&"
export * from "./info.vue?vue&type=script&lang=js&"
import style0 from "./info.vue?vue&type=style&index=0&id=424f2cdc&lang=less&scoped=true&"
import style1 from "./info.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424f2cdc",
  null
  
)

export default component.exports