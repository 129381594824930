<style lang="less" scoped>

  @import "../../../assets/css/variables";
  .carouselImage {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

</style>
<style>
  .el-carousel__item img {
    width: 100%;
    height: 100%;
   color: #475669;
   font-size: 18px;
   opacity: 0.75;
   line-height: 100px;
   margin: 0;
  }
</style>
<template :is="view"
          transition="fade"
          transition-mode="out-in">
  <div class="page-wrapper" id="pgInfo">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/service/repair/list' }">运维服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/infobase/list' }">运维知识库</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/infobase/info' }">运维知识详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container">
      <div class="" style="text-align:center;">
        <h2>{{KnowledgeInfo.title}}</h2>
        <span style="display: inline-block;padding-bottom:20px;font-size:12px;">更新时间：{{moment(KnowledgeInfo.updateTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
        <el-carousel height="280px" style="width:580px;margin: 0 auto;">
          <el-carousel-item v-for="item in imgList" :key="item" @click.native="showBigImage" style="cursor: pointer;">
            <img :src="item"></img>
          </el-carousel-item>
        </el-carousel>
        <div style="text-indent: 2em;width:580px;margin:0 auto;text-align: left;padding: 20px 0;font-size:14px;"
             v-html="KnowledgeInfo.content">
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showBigImages" :close-on-click-modal="false" close-on-press-escape size="small">
      <el-carousel  height="450px"  arrow="hover" style="width:640px;margin: 0 auto;"
                    indicator-position="outside" :autoplay="false" >
        <el-carousel-item class="carouselImage"  v-lazy:background-image="item" style="height:450px;"
                          v-for="item in imgList">
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>

  import moment from 'moment';
  import {getOneKnowledgeBase} from '../../../services/knowledgeBase';
  import {Notification} from 'element-ui';
  import {PAGE_SIZE} from '../../../config';

  export default {
    data() {
      return {
        moment,
        KnowledgeInfo: '',
        showBigImages:false,
        imgList: []
      }
    },
    methods: {
      async getknowledgeInfo(){
        let id = this.$route.query.id;
        const responseData = await getOneKnowledgeBase(id);
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.KnowledgeInfo = responseData.data;
          this.imgList = responseData.data.image.split(',');
        }
      },
      /**
       * 弹出大图
       */
      showBigImage(){
        this.showBigImages = true;
      }
    },
    async created(){
      this.getknowledgeInfo();
    }
  }

</script>
