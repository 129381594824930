/**
 * date: 2017-06-08
 * autor: Jorken
 * description: 运维知识库service
 */

import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 根据等级ID和key查找说有运维信息
 * @param  categoryId[Int]  key[String]  pager[Object]
 * @return {[Object]} infoBase
 */
export async function getKnowledgeBase(
  categoryId,
  key,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("knowledgeBase", { categoryId, key, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建一条知识记录
 * @param  {[Object]} infoBase 创建所需提交的信息
 * @return {[Object]} infoBase
 */
export async function createKnowledgeBase(infoBase) {
  try {
    return await API.post("knowledgeBase", infoBase);
  } catch (error) {
    return error;
  }
}

/**
 * 更新一条知识记录
 * @param  {[Object]} infoBase 修改更新所需提交参数
 * @return {[Object]}  更新后的信息记录
 */
export async function updateKnowledgeBase(infoBase) {
  try {
    return await API.post("knowledgeBase/update", infoBase);
  } catch (error) {
    return error;
  }
}

/**
 * 删除一条只是记录
 * @param  {[String]} id 当前记录的ID
 * @return {[Object]} 返回删除信息
 */
export async function deleteKnowledgeBase(id) {
  try {
    return await API.del("knowledgeBase/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 获取记录的详情信息
 * @param  {[String]} id 当前记录的ID
 * @return {[Object]} 返回记录的详情信息
 */
export async function getOneKnowledgeBase(id) {
  try {
    return await API.get("knowledgeBase/" + id);
  } catch (error) {
    return error;
  }
}
